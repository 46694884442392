//Add unsubscribe page.
import React, { useEffect, useState, ReactElement } from "react"
import {VStack, Text} from "@chakra-ui/react"

const UnsubscribePage: React.FC<{ location }> = ({
  location,
}): ReactElement => {
  const queryParams = new URLSearchParams(location.search)
  const artist = queryParams.get("artist")
  const customer = queryParams.get("customer")
  const subscription = queryParams.get("subscription")

  if (subscription === "paid") {
    //Handle customer portal here.
  }
  //Handle fetch to unsubscribe endpoint here.
  return(
<VStack>
  <Text>
    This will be the unsubscribe portal. Under construction.
  </Text>
</VStack>
  )
}
export default UnsubscribePage
